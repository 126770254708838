import React, {useState, useEffect, useCallback} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from "@mui/material/Typography";
import TablePagination from '@mui/material/TablePagination';
import {Accordion, AccordionDetails, AccordionSummary, ButtonGroup} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ArrowDownwardIcon() {
    return null;
}

function StockItemList() {
    const [stockActiveItems, setStockActiveItems] = useState([]);
    const [stockFullItems, setStockFullItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = stockActiveItems.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchStockItems();
        getTotalStockItems();
    }, []);

    const getTotalStockItems = useCallback(async () => {
        // get total stock items
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENPOINT}stock`);
            setStockFullItems(response.data);
        } catch (error) {
            console.error(error);
        }
    });

    const fetchStockItems = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENPOINT}stock/?status=active`);
            console.log(response.data);
            setStockActiveItems(response.data);
            setStockFullItems(response.data.length);
        } catch (err) {
            setError('Failed to fetch stock items: ' + err);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleIgnore = useCallback(async (id) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_ENPOINT}${id}`);
            await fetchStockItems();
        } catch (err) {
            console.error(err);
        }
    }, [fetchStockItems]);

    const handleFilter = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENPOINT}stock/filter`);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleEvaluate = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENPOINT}stock/evaluate`);
        } catch (error) {
            console.error(error);
        }
    }, []);

    function handlePurge() {
        try {
            const response = axios.get(`${process.env.REACT_APP_API_ENPOINT}stock/purge`);
        } catch (error) {
            console.error(error);
        }
    }

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box sx={{maxWidth: 1024, margin: 'auto', overflow: 'hidden', paddingBottom: '100px'}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Filter Action</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button onClick={() => handleFilter()} color="primary"
                                    sx={{mr: 1, mt: 1, bgcolor: 'success.main', '&:hover': {bgcolor: 'success.dark',}}}>
                                Filter
                            </Button>
                            <Button onClick={() => handleEvaluate()} color="secondary"
                                    sx={{mr: 1, mt: 1, bgcolor: 'info.main', '&:hover': {bgcolor: 'info.dark'}}}>
                                Evaluate
                            </Button>
                            <Button onClick={() => handlePurge()} color="secondary"
                                    sx={{mt: 1, bgcolor: 'error.main', '&:hover': {bgcolor: 'error.dark'}}}>
                                Purge
                            </Button>
                        </ButtonGroup>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Typography>
                There has <span>{stockActiveItems.length}</span> items is activated  out of <span>{stockFullItems.length}</span> stock items.
            </Typography>

            <Typography variant="h4" gutterBottom>
                Stock List
            </Typography>

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">No</TableCell>
                            <TableCell align="center">Stock ID</TableCell>
                            <TableCell align="center">Company Name</TableCell>
                            <TableCell align="center">Exchange</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map((stock, index) => (
                            <TableRow key={stock.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell align="center">{indexOfFirstItem + index + 1}</TableCell>
                                <TableCell align="center">{stock.id}</TableCell>
                                <TableCell>
                                    <a href={`https://s.cafef.vn/${stock.url}`} target="_blank"
                                       rel="noopener noreferrer">
                                        {stock.name}
                                    </a>
                                </TableCell>
                                <TableCell align="center">{stock.type}</TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={() => handleIgnore(stock.id)}
                                            color="secondary">
                                        Ignore
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={stockActiveItems.length}
                    page={currentPage - 1}
                    onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                    rowsPerPage={itemsPerPage}
                    onRowsPerPageChange={(event) => {
                        setItemsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(1); // Reset to the first page
                    }}
                />
            </TableContainer>
        </Box>
    );
}

export default StockItemList;