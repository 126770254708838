import React from 'react';
import { AppBar, Toolbar, Typography, Container, Link } from '@mui/material';

const Footer = () => {
    return (
        <AppBar position="fixed" color="primary" component="footer" sx={{ top: 'auto', bottom: 0 }}>
            <Container maxWidth="lg">
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Typography variant="body1">
                        © 2024 Stock Filter Project. All rights reserved.
                    </Typography>
                    <Link href="#" color="inherit" style={{ textDecoration: 'none' }}>
                        Privacy Policy
                    </Link>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Footer;
