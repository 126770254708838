// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = ({ onLogin }) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Stock Filter Project
                </Typography>
                <Button color="inherit">
                    <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
                </Button>
                <Button color="inherit">
                    <Link to="/about" style={{ textDecoration: 'none', color: 'white' }}>About</Link>
                </Button>
                <Button color="inherit">
                    <Link to="/contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
                </Button>
                <Button color="inherit">
                    <Link  to="/login" style={{ textDecoration: 'none', color: 'white' }}>Login</Link>
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
