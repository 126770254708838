import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Layout from "./components/Layout";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";

function App() {
    return (
        <div>
        <Router>
            <Routes>
                <Route path="/" element={<Layout><Home /></Layout>} />
                <Route path="/about" element={<Layout><About /></Layout>} />
                <Route path="/contact" element={<Layout><Contact /></Layout>} />
                <Route path="/login" element={<Layout><Login /></Layout>} />
                <Route path="/signup" element={<Layout><SignUp /></Layout>} />
            </Routes>
        </Router>
        </div>
    );
}

export default App;
