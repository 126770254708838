import React from 'react';
import StockItemList from '../components/StockItemList';
import { Box } from '@mui/material';
import Typography from "@mui/material/Typography";

function Home() {
    return <div>
        <Box component="main">
            <StockItemList/>
        </Box>
    </div>
}

export default Home;
